/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateMobileUserRequestDto } from '../models/CreateMobileUserRequestDto';
import type { CreateMobileUserResponseDto } from '../models/CreateMobileUserResponseDto';
import type { GreetieDto } from '../models/GreetieDto';
import type { GreetieInfoDto } from '../models/GreetieInfoDto';
import type { MobileUserDto } from '../models/MobileUserDto';
import type { NotificationDto } from '../models/NotificationDto';
import type { UpdateMobileUserDto } from '../models/UpdateMobileUserDto';
import type { VoucherDto } from '../models/VoucherDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class MobileUserService {
    /**
     * @param requestBody
     * @returns CreateMobileUserResponseDto OK
     * @throws ApiError
     */
    public static postApiMobileuser(
        requestBody?: CreateMobileUserRequestDto,
    ): CancelablePromise<CreateMobileUserResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/mobileuser',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id
     * @returns MobileUserDto OK
     * @throws ApiError
     */
    public static getApiMobileuser(
        id: string,
    ): CancelablePromise<MobileUserDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/mobileuser/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id
     * @param requestBody
     * @returns MobileUserDto OK
     * @throws ApiError
     */
    public static postApiMobileuser1(
        id: string,
        requestBody?: UpdateMobileUserDto,
    ): CancelablePromise<MobileUserDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/mobileuser/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns GreetieInfoDto OK
     * @throws ApiError
     */
    public static getApiMobileuserSentgreeties(): CancelablePromise<Array<GreetieInfoDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/mobileuser/sentgreeties',
        });
    }
    /**
     * @returns GreetieInfoDto OK
     * @throws ApiError
     */
    public static getApiMobileuserGreeties(): CancelablePromise<Array<GreetieInfoDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/mobileuser/greeties',
        });
    }
    /**
     * @returns GreetieInfoDto OK
     * @throws ApiError
     */
    public static getApiMobileuserReceivedgreeties(): CancelablePromise<Array<GreetieInfoDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/mobileuser/receivedgreeties',
        });
    }
    /**
     * @returns VoucherDto OK
     * @throws ApiError
     */
    public static getApiMobileuserVouchers(): CancelablePromise<Array<VoucherDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/mobileuser/vouchers',
        });
    }
    /**
     * @param deviceId
     * @param token
     * @returns MobileUserDto OK
     * @throws ApiError
     */
    public static postApiMobileuserSetExpoToken(
        deviceId?: string,
        token?: string,
    ): CancelablePromise<MobileUserDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/mobileuser/setExpoToken',
            query: {
                'deviceId': deviceId,
                'token': token,
            },
        });
    }
    /**
     * @param deviceId
     * @returns MobileUserDto OK
     * @throws ApiError
     */
    public static postApiMobileuserRemoveExpoToken(
        deviceId?: string,
    ): CancelablePromise<MobileUserDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/mobileuser/removeExpoToken',
            query: {
                'deviceId': deviceId,
            },
        });
    }
    /**
     * @returns NotificationDto OK
     * @throws ApiError
     */
    public static getApiMobileuserNotifications(): CancelablePromise<Array<NotificationDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/mobileuser/notifications',
        });
    }
    /**
     * @returns GreetieDto OK
     * @throws ApiError
     */
    public static deleteApiMobileuserRemove(): CancelablePromise<GreetieDto> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/mobileuser/remove',
        });
    }
    /**
     * @param isAllowed
     * @returns MobileUserDto OK
     * @throws ApiError
     */
    public static postApiMobileuserAllowmarketing(
        isAllowed?: boolean,
    ): CancelablePromise<MobileUserDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/mobileuser/allowmarketing',
            query: {
                'isAllowed': isAllowed,
            },
        });
    }
}
