/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GreetieMerchantDto } from '../models/GreetieMerchantDto';
import type { MerchantDto } from '../models/MerchantDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class MerchantService {
    /**
     * @returns GreetieMerchantDto OK
     * @throws ApiError
     */
    public static getApiMerchant(): CancelablePromise<Array<GreetieMerchantDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/merchant',
        });
    }
    /**
     * @returns MerchantDto OK
     * @throws ApiError
     */
    public static getApiMerchantV2(): CancelablePromise<Array<MerchantDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/merchant/v2',
        });
    }
    /**
     * @param id
     * @returns MerchantDto OK
     * @throws ApiError
     */
    public static getApiMerchant1(
        id: string,
    ): CancelablePromise<MerchantDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/merchant/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id
     * @returns GreetieMerchantDto OK
     * @throws ApiError
     */
    public static getApiMerchantProducts(
        id: string,
    ): CancelablePromise<Array<GreetieMerchantDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/merchant/{id}/products',
            path: {
                'id': id,
            },
        });
    }
}
