// @mui

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Box, Container } from '@mui/material';
// theme
import { bgGradient } from 'src/theme/css';
// routes
// hooks
import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useAuthContext } from 'src/auth/hooks';
import { useResponsive } from 'src/hooks/use-responsive';
// components
import Logo from 'src/components/logo';
import { navConfig, navAuthenticatedConfig } from './config-navigation';
//
import { HEADER } from '../config-layout';
import LoginButton from '../_common/login-button';
import AccountPopover from '../_common/account-popover';
import NavMobile from '../_common/nav/mobile';
import NavDesktop from '../_common/nav/desktop';

// ----------------------------------------------------------------------

export default function HeaderStart() {
  const { user } = useAuthContext();
  const mdUp = useResponsive('up', 'md');
  const offsetTop = useOffSetTop(HEADER.H_DESKTOP);

  const renderContent = (
    <>
      {!mdUp && (
        <>
          <NavMobile offsetTop={offsetTop} data={user ? navAuthenticatedConfig : navConfig} />
          <Box
            sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <Logo />
          </Box>
        </>
      )}
      {mdUp && (
        <>
          <Logo />
          <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
            <NavDesktop offsetTop={offsetTop} data={user ? navAuthenticatedConfig : navConfig} />
          </Box>
        </>
      )}

      <Box sx={{ flexGrow: 0 }}>
        {user ? (
          <AccountPopover />
        ) : (
          <LoginButton sx={{ backgroundColor: '#f3df67', color: '#000000', borderColor: '#fff' }} />
        )}
      </Box>
    </>
  );

  return (
    <>
      <AppBar
        sx={{
          ...bgGradient({
            direction: 'to right',
            endColor: '#964AE2',
            startColor: '#47C8D3',
          }),
        }}
      >
        <Container maxWidth="lg" disableGutters>
          <Toolbar
            sx={{
              justifyContent: 'space-between',
            }}
          >
            {renderContent}
          </Toolbar>
        </Container>
      </AppBar>
      <Toolbar />
    </>
  );
}
