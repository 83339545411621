/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateGreetieDto } from '../models/CreateGreetieDto';
import type { CreateGreetieResultDto } from '../models/CreateGreetieResultDto';
import type { GreetieDto } from '../models/GreetieDto';
import type { GreetieInfoDto } from '../models/GreetieInfoDto';
import type { GreetieMediaDto } from '../models/GreetieMediaDto';
import type { GreetiePaymentRefDto } from '../models/GreetiePaymentRefDto';
import type { PaymentCallback } from '../models/PaymentCallback';
import type { PaymentDto } from '../models/PaymentDto';
import type { VoucherDto } from '../models/VoucherDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class GreetieService {
    /**
     * @returns GreetieDto OK
     * @throws ApiError
     */
    public static getApiGreetie(): CancelablePromise<Array<GreetieDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/greetie',
        });
    }
    /**
     * @param requestBody
     * @returns GreetieInfoDto OK
     * @throws ApiError
     */
    public static postApiGreetie(
        requestBody?: CreateGreetieDto,
    ): CancelablePromise<GreetieInfoDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/greetie',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns CreateGreetieResultDto OK
     * @throws ApiError
     */
    public static postApiGreetieCreatev2(
        requestBody?: CreateGreetieDto,
    ): CancelablePromise<CreateGreetieResultDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/greetie/createv2',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id
     * @param markAsViewed
     * @returns GreetieDto OK
     * @throws ApiError
     */
    public static getApiGreetie1(
        id: string,
        markAsViewed: boolean = true,
    ): CancelablePromise<GreetieDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/greetie/{id}',
            path: {
                'id': id,
            },
            query: {
                'markAsViewed': markAsViewed,
            },
        });
    }
    /**
     * @param id
     * @returns number OK
     * @throws ApiError
     */
    public static deleteApiGreetie(
        id: string,
    ): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/greetie/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id
     * @returns GreetieInfoDto OK
     * @throws ApiError
     */
    public static getApiGreetieInfo(
        id: string,
    ): CancelablePromise<GreetieInfoDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/greetie/{id}/info',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param refId
     * @returns GreetieDto OK
     * @throws ApiError
     */
    public static getApiGreetieByRef(
        refId?: string,
    ): CancelablePromise<GreetieDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/greetie/byRef',
            query: {
                'refId': refId,
            },
        });
    }
    /**
     * @param id
     * @param formData
     * @returns GreetieMediaDto OK
     * @throws ApiError
     */
    public static postApiGreetieMedia(
        id: string,
        formData?: {
            file?: Blob;
        },
    ): CancelablePromise<GreetieMediaDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/greetie/{id}/media',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * @param id
     * @param formData
     * @returns GreetieMediaDto OK
     * @throws ApiError
     */
    public static postApiGreetieMediaoverlay(
        id: string,
        formData?: {
            file?: Blob;
        },
    ): CancelablePromise<GreetieMediaDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/greetie/{id}/mediaoverlay',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * @param id
     * @returns GreetiePaymentRefDto OK
     * @throws ApiError
     */
    public static postApiGreetieStartpayment(
        id: string,
    ): CancelablePromise<GreetiePaymentRefDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/greetie/{id}/startpayment',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id
     * @returns PaymentDto OK
     * @throws ApiError
     */
    public static getApiGreetiePayment(
        id: string,
    ): CancelablePromise<PaymentDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/greetie/{id}/payment',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id
     * @returns VoucherDto OK
     * @throws ApiError
     */
    public static getApiGreetieVouchers(
        id: string,
    ): CancelablePromise<Array<VoucherDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/greetie/{id}/vouchers',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id
     * @returns GreetieInfoDto OK
     * @throws ApiError
     */
    public static postApiGreetieMarkasviewed(
        id: string,
    ): CancelablePromise<GreetieInfoDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/greetie/{id}/markasviewed',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static postApiGreetiePaymentCallBack(
        requestBody?: PaymentCallback,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/greetie/paymentCallBack',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
