import { Navigate, useRoutes } from 'react-router-dom';
//
import { GuestGuard } from 'src/auth/guard';
import StartLayout from 'src/layouts/start';

import { authRoutes } from './auth';
import { mainRoutes } from './main';
import { HomePage, publicRoutes } from './public';
import { compactRoutes } from './view';


// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // SET INDEX PAGE WITH HOME PAGE
    {
      path: '/',
      element: (
  
          <StartLayout>
            <HomePage />
          </StartLayout>
      
      ),
    },

    // Auth routes
    ...authRoutes,

    // Public routes
    ...publicRoutes,

    // Main routes
    ...mainRoutes,

    ...compactRoutes,

    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
