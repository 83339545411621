import { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import { AuthGuard } from 'src/auth/guard';

// layouts
import MainLayout from 'src/layouts/main';

// ----------------------------------------------------------------------
export const GreetiePage = lazy(() => import('src/pages/greetie/list'));
export const GreetieDetailsPage = lazy(() => import('src/pages/greetie/details'));
export const GreetieNewPage = lazy(() => import('src/pages/greetie/new'));
export const GreetieViewPage = lazy(() => import('src/pages/greetie/view'));
export const RedeemPage = lazy(() => import('src/pages/redeem/list'));
export const RedeemVoucherRedeemPage = lazy(() => import('src/pages/redeem/redeem'));
export const VoucherPage = lazy(() => import('src/pages/voucher/list'));
export const VoucherDetailsPage = lazy(() => import('src/pages/voucher/details'));
export const MyGreetiesPage = lazy(() => import('src/pages/my'));

// ----------------------------------------------------------------------

export const mainRoutes = [
  {
    element: (
      <AuthGuard>
        <MainLayout>
          <Outlet />
        </MainLayout>
      </AuthGuard>
    ),
    children: [
      {
        path: 'redeem',
        children: [
          { element: <RedeemPage />, index: true },
          { path: ':id', element: <RedeemPage /> },
          { path: 'voucher/:id', element: <RedeemVoucherRedeemPage /> },
        ],
      },
      {
        path: 'voucher',
        children: [
          { element: <VoucherPage />, index: true },
          { path: 'list', element: <VoucherPage /> },
          { path: ':id', element: <VoucherDetailsPage /> },
        ],
      },
      {
        path: 'greetie',
        children: [
          { element: <GreetiePage />, index: true },
          { path: 'list', element: <GreetiePage /> },
          { path: 'new', element: <GreetieNewPage /> },
          { path: 'details/:id', element: <GreetieDetailsPage /> },
        ],
      },

      { path: 'my/:id', element: <MyGreetiesPage /> },
    ],
  },
];
